import React, { Component } from "react";
import blogmono from "../../assets/image/Blog/monocarton-section.png"
import blogvarious from "../../assets/image/Blog/folding-box-section.png"
import blogbenefits from "../../assets/image/Blog/benifits-section.png"


class RecentBlogSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scroll: false,
        };
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        if (window.pageYOffset > 0) {
            this.setState({ scroll: "scroll" });
        } else {
            window.addEventListener("scroll", this.handleScroll);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll() {
        this.setState({ scroll: window.scrollY > 200 });
    }

    render() {
        const { scroll } = this.state;
        return (
            <div
                className={
                    scroll ? "active sticky-top recent-blogs" : "sticky-top recent-blogs"
                }
            >
                <h5 className="fw-bold">Recent Blogs</h5>
                <div className="blog-btn-card mt-lg-0 mt-4 px-0">
                    <ul className="mb-0">
                        <li className="d-flex mb-3 blog-side-card">
                            <div className="blog-side-image">
                                <a href="/what-is-mono-carton">
                                    <img
                                        src={blogmono}
                                        className="img-fluid"
                                        alt="Mono Carton"
                                    />
                                </a>
                            </div>
                            <div className="blog-side-content blog-small">
                                <div>
                                    <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth="1.5"
                                        viewBox="0 0 24 24"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                                        ></path>
                                    </svg>
                                    <span className="my-3">03-01-2025</span>
                                </div>
                                <a href="/what-is-mono-carton">
                                    What is Mono Carton?
                                </a>
                            </div>
                        </li>
                        <li className="d-flex mb-3 blog-side-card">
                            <div className="blog-side-image">
                                <a href="/what-are-the-various-types-of-folding-boxes">
                                    <img
                                        src={blogvarious}
                                        className="img-fluid"
                                        alt="various types of Folding Boxes"
                                    />
                                </a>
                            </div>
                            <div className="blog-side-content blog-small">
                                <div>
                                    <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth="1.5"
                                        viewBox="0 0 24 24"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                                        ></path>
                                    </svg>
                                    <span className="my-3">05-01-2025</span>
                                </div>
                                <a href="/what-are-the-various-types-of-folding-boxes">
                                    What are the various types of Folding Boxes?
                                </a>
                            </div>
                        </li>
                        <li className="d-flex mb-3 blog-side-card">
                            <div className="blog-side-image">
                                <a href="/benefits-of-folding-cartons-for-businesses-and-consumers">
                                    <img
                                        src={blogbenefits}
                                        className="img-fluid"
                                        alt="benefits of Folding cartons for businesses and consumers  "
                                    />
                                </a>
                            </div>
                            <div className="blog-side-content blog-small">
                                <div>
                                    <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth="1.5"
                                        viewBox="0 0 24 24"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                                        ></path>
                                    </svg>
                                    <span className="my-3">08-01-2025</span>
                                </div>
                                <a href="/benefits-of-folding-cartons-for-businesses-and-consumers">
                                    What are the benefits of Folding cartons for businesses and consumers                                 </a>
                            </div>
                        </li>
                        {/* <li className="d-flex mb-3 blog-side-card">
                            <div className="blog-side-image">
                                <a href="/local-seo-tips-to-dominate-your-niche-in-2025">
                                    <img
                                        src={blogsmall}
                                        className="img-fluid"
                                        alt="Local SEO Tips to Dominate Your Niche in 2025"
                                    />
                                </a>
                            </div>
                            <div className="blog-side-content blog-small">
                                <div>
                                    <svg
                                        stroke="currentColor"
                                        fill="none"
                                        strokeWidth="1.5"
                                        viewBox="0 0 24 24"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                                        ></path>
                                    </svg>
                                    <span className="my-3">12-01-2025</span>
                                </div>
                                <a href="/local-seo-tips-to-dominate-your-niche-in-2025">
                                    Why Attractive Packaging drives sales..                                </a>
                            </div>
                        </li> */}


                        {/* Additional blog entries go here */}
                    </ul>

                    {/* <div className="read-more-button d-flex align-items-center justify-content-center">
                        <a href="/what-is-monocarton" class="btn text-light talk mt-5px">Read More</a>

                    </div> */}

                </div>
            </div>
        );
    }
}

export default RecentBlogSection;
