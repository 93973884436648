import React, { Component } from "react";
import "react-multi-carousel/lib/styles.css";
import "react-responsive-modal/styles.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import AboutUs from "./AboutUs";
import "./App.css";
import Footer from "./Footer";
import Header from "./Header";
import HomeBanner from "./HomeBanner";
import Parallax from "./Parallax";
import Products from "./Products";
import PostApiCall from "./components/helper/Api";

import CustomPackaginBox from "./Pages/CommonStickerCustomBox/CustomPackaginBox";
import Sticker from "./Pages/CommonStickerCustomBox/Sticker";

import ReactGA from "react-ga4";
import BlogSection from "./components/BlogSection/BlogSection";
ReactGA.initialize([
  {
    trackingId: "G-2VDZ0VM2P4",
  },
]);

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1190 },
    items: 4,
  },
  tablet1: {
    breakpoint: { max: 1190, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

class Home extends Component {
  render() {
    return (
      <div className="App">
        <Header></Header>
        <HomeBanner></HomeBanner>
        <section class="section overview bg-gray" id="overview">
          <div class="container">
            <div class="row">
              <div class="col-lg-5">
                <div class="middle-box">
                  <div class="middle">
                    <div class="overview-txt">
                      {/* <h3>Welcome to</h3> */}
                      <h1 className="new-common-h1-heading">Welcome to</h1>
                      <h2>PIO PACKAGING</h2>
                      <p>
                        Whether your company is launching a new product range or
                        simply looking for a supplier of attractive and
                        innovative printed packaging, we can suggest effective
                        ideas and solutions at reasonable costs with the highest
                        quality!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-7">
                <div class="overview-img">
                  <img src="assets/img/overview.jpg" alt="Overview" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="section Product section-online-store " id="product">
          {/*  Custom box componenet */}
          <CustomPackaginBox />
          {/*  Sticker box componenet */}
          <Sticker />
        </section>

        <section
          class="section industries pt-md-0 pt-3 pb-2 pb-md-3"
          id="industries"
        >
          <div class="container-fluid px-md-4 px-3">
            <div class="row">
              <div class="col-md-12">
                <div class="section-title text-center mb-md-4 mb-3">
                  <h2 className="mb-0 pb-2">
                    WE SUPPLY CARTONS FOR THE FOLLOWING INDUSTRIES
                  </h2>
                </div>
              </div>
            </div>
            <div class="row px-1 ">
              <div class="col-lg-4">
                <div class="industries-box">
                  <div class="industries-box-img">
                    <img
                      class="img-fluid"
                      src="assets/img/industries/cosmetics-health-beauty.jpg"
                      alt="COSMETICS - HEALTH - BEAUTY"
                    />
                    <div class="industries-box-titel">
                      <h2>COSMETICS - HEALTH - BEAUTY</h2>
                    </div>
                  </div>
                  <div class="industries-box-txt">
                    <p>
                      We understand the needs of our cosmetics manufacturing
                      customers. The packaging of their product is the
                      communication medium that actually sells their products.
                      It’s essential that the printed carton has an exciting and
                      extraordinary shelf presence. Our design and manufacturing
                      team can create looks for you that range from glitzy
                      cartons that are printed on specialty materials with
                      assorted embellishments to crisp, smooth cartons printed
                      with the sharpest type and brightest graphics.
                    </p>
                  </div>
                </div>
                <div class="industries-box">
                  <div class="industries-box-img">
                    <img
                      class="img-fluid"
                      src="assets/img/industries/medical-and-pharmaceutical.jpg"
                      alt="MEDICAL & PHARMACEUTICAL"
                    />
                    <div class="industries-box-titel">
                      <h2>MEDICAL & PHARMACEUTICAL</h2>
                    </div>
                  </div>
                  <div class="industries-box-txt">
                    <p>
                      We specialize in serving the folding carton requirements
                      of the medical devices, pharmaceutical and biotech
                      industries. We can custom design a folding carton to fit
                      your product. Our cartons are manufactured to
                      international standards with strict adherence to those
                      operating procedures to meet any stringent packaging
                      requirements of this industry.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="industries-box">
                  <div class="industries-box-img">
                    <img
                      class="img-fluid"
                      src="assets/img/industries/food-and-confectionery.jpg"
                      alt="FOOD & CONFECTIONERY"
                    />
                    <div class="industries-box-titel">
                      <h2>FOOD & CONFECTIONERY</h2>
                    </div>
                  </div>
                  <div class="industries-box-txt">
                    <p>
                      We provide innovative and creative packaging for the food
                      and confectionery industries. We offer unique shapes,
                      graphics and graphic enhancements. From simple tuck-end
                      boxes, to custom creative specialty packaging, we can
                      assist you in creating the right carton for your unique
                      product from concept to completion. The latest trend in
                      design is shape differentiation. Call us to see what we
                      can do for you.
                    </p>
                  </div>
                </div>
                <div class="industries-box">
                  <div class="industries-box-img">
                    <img
                      class="img-fluid"
                      src="assets/img/industries/consumer-product.jpg"
                      alt="CONSUMER PRODUCTS"
                    />
                    <div class="industries-box-titel">
                      <h2>CONSUMER PRODUCTS</h2>
                    </div>
                  </div>
                  <div class="industries-box-txt">
                    <p>
                      Packaging is a serious mainstream communications medium.
                      We have extensive experience in the consumer products
                      industry that can help you create a folding carton that’s
                      more than just a package. Your folding cartons serve as
                      more than mere containers for stacking or protecting the
                      products inside. In today’s market, your cartons must look
                      appealing and grab the consumer’s eye. You need
                      innovation, shelf presence and eye appeal.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="industries-box">
                  <div class="industries-box-img">
                    <img
                      class="img-fluid"
                      src="assets/img/industries/industrial-products.jpg"
                      alt="INDUSTRIAL PRODUCTS"
                    />
                    <div class="industries-box-titel">
                      <h2>INDUSTRIAL PRODUCTS</h2>
                    </div>
                  </div>
                  <div class="industries-box-txt">
                    <p>
                      Industrial products have a very specific need to be
                      packaged in a scientific manner so that the product not
                      only is housed in the package in a snug, safe and
                      economically sized package but also has the right appeal
                      to give it a premium look and to speak to the customer
                      about the product inside and also about the company that
                      produced it. At Pio Packaging we have a skilled team who
                      can give you the right advice and guidance to make your
                      packaging not only attractive and functional but also at
                      an economic cost.
                    </p>
                  </div>
                </div>
                <div class="industries-box">
                  <div class="industries-box-img">
                    <img
                      class="img-fluid"
                      src="assets/img/industries/apparel-and-innerwear.jpg"
                      alt="APPAREL & INNERWEAR"
                    />
                    <div class="industries-box-titel">
                      <h2>APPAREL & INNERWEAR</h2>
                    </div>
                  </div>
                  <div class="industries-box-txt">
                    <p>
                      Packaging is an important part of the apparel & Innerwear
                      manufacturing process. A well packaged garment attracts
                      the customer’s attention to the product and also adds
                      value to the product making the customer feel that this is
                      indeed a premium product. Besides this the other important
                      objective is to prevent damage to the product during
                      transit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Products></Products>
        <Parallax></Parallax>
        <AboutUs></AboutUs>
        <BlogSection />
        <Footer></Footer>
      </div>
    );
  }
}

export default Home;
