import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Header from "../../Header";
import piologo from "../../assets/image/pio-logo.png";
import RecentBlogSection from "./RecentBlogSection";
import blogbig from "../../assets/image/blog-big.png"
import Footer from "../../Footer";
import blogmono from "../../assets/image/Blog/monocarton-section.png"
import blogvarious from "../../assets/image/Blog/folding-box-section.png"
import blogbenefits from "../../assets/image/Blog/benifits-section.png"



class Blogs extends React.Component {
    render() {
        return (
            <>
                <div>
                    <Header></Header>
                    <section className="page-banner">+
                        <div className="container-fluid px-md-4 px-3">
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <div className="top-breadcrumb">
                                        <div className="w-100 text-center inner-pio-phn-logo justify-content-center d-md-none d-flex">
                                            <img src={piologo} alt="pio-packaging" />
                                        </div>

                                        <ul>
                                            <li className="breadcrumb-item">
                                                <a href="http://piopackaging.com">Home</a>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <a href="http://piopackaging.com#products">Blogs</a>
                                            </li>
                                            {/* <li className="breadcrumb-item active" aria-current="page">
                                                What is Mono Carton?
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>

                    <section className="blog-page pt-5 mt-lg-5 pb-lg-5">
                        <div className="container-fluid px-lg-5">
                            <div class="section-title text-center">
                                <h1 className="mono-font-weight">Blogs</h1>
                                <p>
                                    Innovative and Smart Packaging Solutions Designed for a Better Tomorrow






                                </p>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 mb-3">
                                    <div className="blog-card">
                                        <div className="blog-page-image">
                                            <a href="/what-is-mono-carton">
                                                <img
                                                    className="img-fluid"
                                                    src={blogmono}
                                                    alt="Mono Carton"
                                                />

                                            </a>

                                        </div>
                                        <div className="blog-page-txt blog-content">
                                            <div className="d-flex align-items-center">
                                                <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>
                                                <span >03-01-2025</span>
                                            </div>
                                            <h2>What is mono cartons?</h2>

                                            <p>
                                                A mono carton is a single-layer box made from a sheet of cardboard or paperboard that's printed, die cut to a shape, folded and glued together. They are also known as folding cartons. Mono cartons are a common packaging choice for a variety of industries, including retail, food, pharmaceutical, healthcare, lifestyle, clothes, toys, confectionery, baked goods, electrical & electronics, Industrial and a host of endless products.
                                            </p>
                                            <a href="/what-is-mono-carton" className="btn mt-3">Read More</a>
                                        </div>


                                    </div>
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <div className="blog-card">
                                        <div className="blog-page-image">
                                            <a href="/what-are-the-various-types-of-folding-boxes">
                                                <img
                                                    className="img-fluid border-bottom"
                                                    src={blogvarious}
                                                    alt="various types of Folding Boxes"
                                                />

                                            </a>

                                        </div>
                                        <div className="blog-page-txt blog-content">
                                            <div className="d-flex align-items-center">
                                                <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>
                                                <span >05-01-2025</span>
                                            </div>
                                            <h2>What are the various types of Folding Boxes?</h2>

                                            <p>
                                                These boxes feature flaps that tuck into the box body, providing a secure closure without the need for tape or glue. They're commonly used for retail products, such as small electronics, cosmetics, and food items. Tuck-end boxes are quick to assemble and are lightweight, making them a popular choice in the packaging industry.
                                            </p>
                                            <a href="/what-are-the-various-types-of-folding-boxes" className="btn mt-3">Read More</a>
                                        </div>


                                    </div>
                                </div>
                                <div className="col-lg-4 mb-3">
                                    <div className="blog-card">
                                        <div className="blog-page-image">
                                            <a href="/benefits-of-folding-cartons-for-businesses-and-consumers">
                                                <img
                                                    className="img-fluid"
                                                    src={blogbenefits}
                                                    alt="benefits of Folding cartons for businesses and consumers"
                                                />

                                            </a>

                                        </div>
                                        <div className="blog-page-txt blog-content">
                                            <div className="d-flex align-items-center">
                                                <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"></path></svg>
                                                <span >08-01-2025</span>
                                            </div>
                                            <h2>What are the benefits of Folding cartons for businesses and consumers </h2>

                                            <p>
                                                Folding boxes are a flexible, cost-effective, and environmentally friendly packaging solution that meets the needs of modern businesses and consumers. With their versatility, ease of storage, and potential for customization, folding boxes offer an ideal solution for packaging a wide variety of products. Whether you're a small business looking to reduce packaging costs or a large corporation seeking sustainable alternatives, folding boxes provide a practical and efficient option that supports both branding and eco-friendly initiatives.
                                            </p>
                                            <a href="/benefits-of-folding-cartons-for-businesses-and-consumers" className="btn mt-3">Read More</a>
                                        </div>


                                    </div>

                                </div>
                            </div>

                        </div>

                    </section>
                </div>
                <Footer></Footer>

            </>
        )

    }
}

export default Blogs;
