import React from "react";
import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import PostApiCall from "./components/helper/Api";
import Notiflix from "notiflix";
import moment from "moment";
import { store } from "./components/context/store";
//import SelectedBox from "";

class Header extends React.Component {
  static contextType = store;
  constructor(props) {
    super(props);
    this.state = {
      changePasswordCard: false,
      changePasswordVerify: false,
      OldPassword: "",
      NewPassword: "",
      ConfirmPassword: "",
      OldPassword2: "",
      LoginDetail: [],
      Email: "",
      PasswordRegex:
        /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/,
      EmailRegex:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      isOldPasswordVisible: false,
      isNewPasswordVisible: false,
      isConPasswordVisible: false,
      Salt: "",
      mobileMenuShow: false,
    };
  }

  componentDidMount() {
    const store = this.context;

    var customer = localStorage.getItem("CustomerData");
    var CustomerDetails = JSON.parse(customer);

    if (CustomerDetails != null || CustomerDetails != undefined) {
      this.setState({
        Email: CustomerDetails[0].fld_email,
      });
    }
  }

  saveLogin = () => {
    if (this.state.Email != "") {
      if (this.state.EmailRegex.test(this.state.Email)) {
        if (this.state.OldPassword != "") {
          this.onLogin();
        } else {
          Notiflix.Notify.failure("Please enter your password.");
        }
      } else {
        Notiflix.Notify.failure("Please enter valid email.");
      }
    } else {
      Notiflix.Notify.failure("Please enter your email.");
    }
  };

  onLogin = () => {
    Notiflix.Loading.circle("Please wait...");
    PostApiCall.postRequest(
      {
        email: this.state.Email,
        password: this.state.OldPassword,
        action: "Login",
        actiondate: moment().format("lll"),
      },
      "AddwebsiteLogin"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          // localStorage.setItem('CustomerData', JSON.stringify(obj.data))
          Notiflix.Loading.remove();
          this.setState({
            changePasswordVerify: false,
            changePasswordCard: true,
          });
          this.onPostSalt();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure(obj.data);
        }
      })
    );
  };

  onPostSalt() {
    //////console.log(this.state.Email)
    PostApiCall.postRequest(
      {
        email: this.state.Email,
      },
      "Get_SaltByEmailId_ForgotPassword"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          this.setState({
            Salt: obj.data,
          });
          //  //////console.log(this.state.Salt.fld_salt)
          //////console.log(this.state.Salt[0].fld_salt)
        }
      })
    );
  }

  SavePassword = () => {
    if (this.state.NewPassword != "") {
      if (this.state.PasswordRegex.test(this.state.NewPassword)) {
        if (this.state.ConfirmPassword != "") {
          if (this.state.ConfirmPassword == this.state.NewPassword) {
            this.onPostChangePassword();
          } else {
            Notiflix.Notify.failure(
              "New Password and Confirm Password do not match."
            );
          }
        } else {
          Notiflix.Notify.failure("Please confirm your password.");
        }
      } else {
        Notiflix.Notify.failure(
          "Password must be at least 6 characters  & contains a number and a letter"
        );
      }
    } else {
      Notiflix.Notify.failure("Please enter new password.");
    }
  };

  onPostChangePassword() {
    var customer = localStorage.getItem("CustomerData");
    var CustomerDetails = JSON.parse(customer);

    Notiflix.Loading.circle("");

    PostApiCall.postRequest(
      {
        userid: CustomerDetails[0].fld_customerid,
        password: this.state.ConfirmPassword,
        salt: this.state.Salt[0].fld_salt,
      },
      "ChangeCustomerPassword"
    ).then((results) =>
      // const objs = JSON.parse(result._bodyText)
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Password Successfully updated");
          localStorage.removeItem("CustomerData");
          window.location.href = "/login";
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("something went wrong, try again later");
        }
      })
    );
  }

  render() {
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    let name = logindetails == null ? null : logindetails[0].fld_name;

    return (
      <header class="header">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <nav class="navbar">
                <div id="berger-menu" onClick={() => {
                  this.context.setShowOnlineOrder(false)
                }}>
                  <div class="menu-bar"></div>
                  <div class="menu-bar"></div>
                  <div class="menu-bar"></div>
                </div>
                <div id="login-link">
                  <a
                    href="#"
                    class="user-login"
                    onClick={() => {
                      if (localStorage.getItem("CustomerData") == null) {
                        localStorage.setItem("currentPage", "Header");
                        window.location.href = "/login";
                      }
                    }}
                    style={{
                      display:
                        localStorage.getItem("CustomerData") == null
                          ? "block"
                          : "none",
                    }}
                  >
                    <i class="fa-regular fa-user"></i>
                  </a>
                  {/* <a
                    onClick={() => {
                      this.setState({ customBoxSelection: true });
                    }}
                    class="user-login"
                  >
                    <i class="fa-regular fa-eye"></i>
                  </a> */}
                </div>
                <div id="overlay-sections"></div>
                <ul class="menu">
                  <div id="berger-menu" onClick={() => {
                    this.context.setShowOnlineOrder(true)
                  }}>
                    <div class="menu-bar"></div>
                    <div class="menu-bar"></div>
                    <div class="menu-bar"></div>
                  </div>
                  <div class="logo show-mobile">
                    <img src="/assets/img/pio-logo.png" alt="Pio Packaging" />
                  </div>
                  <li>
                    {/* <HashLink
                      to="/#home"
                      activeclassname="active-menu"
                      scroll={(el) =>
                        el.scrollIntoView({
                          behavior: "smooth",
                        })
                      }
                    >
                      Home
                    </HashLink> */}
                    <a href="/#home" >Home</a>
                  </li>
                  <li>
                    {/* <HashLink
                      to="/#overview"
                      scroll={(el) =>
                        el.scrollIntoView({
                          behavior: "smooth",
                        })
                      }
                    >
                      Overview
                    </HashLink> */}
                    <a href="/#overview">Overview</a>
                  </li>
                  <li>
                    <a href="/custom-packaging">Why Custom Packaging?</a>
                  </li>
                  <li>
                    {" "}
                    {/* <HashLink
                      to="/#industries"
                      scroll={(el) =>
                        el.scrollIntoView({
                          behavior: "smooth",
                        })
                      }
                    >
                      Industries
                    </HashLink> */}
                    <a href="/#industries">Industries</a>
                  </li>
                  <li>
                    <a href="/#products" id="has-child">
                      Products <i class="fa fa-angle-down"></i>
                    </a>
                    <ul class="dp-menu" id="dp-menu">
                      <li>
                        <a href="/mono-cartons" class="drop-text">
                          Mono Cartons
                        </a>
                      </li>
                      <li>
                        <a href="/bespoke-packaging" class="drop-text">
                          Bespoke Packaging
                        </a>
                      </li>
                      <li>
                        <a href="/creative-packaging" class="drop-text">
                          Creative Packaging
                        </a>
                      </li>
                      <li>
                        <a href="/retail-food-packaging" class="drop-text">
                          Retail Food Packaging
                        </a>
                      </li>
                      <li>
                        <a
                          href="/product-visibility-packaging"
                          class="drop-text"
                        >
                          Product visibility Packaging
                        </a>
                      </li>
                      <li>
                        <a href="/sleeve-packaging" class="drop-text">
                          Sleeve Packaging
                        </a>
                      </li>
                      <li>
                        <a href="/hinged-lid-boxes" class="drop-text">
                          Hinged Lid Boxes
                        </a>
                      </li>
                      <li>
                        <a href="/drawer-type-box" class="drop-text">
                          Drawer Type Box
                        </a>
                      </li>
                      <li>
                        <a href="/base-and-lid-boxes" class="drop-text">
                          Base &amp; Lid Boxes
                        </a>
                      </li>
                      <li>
                        <a href="/display-packaging" class="drop-text">
                          Display Packaging
                        </a>
                      </li>
                      <li>
                        <a href="/gusset-packaging" class="drop-text">
                          Gusset Packaging
                        </a>
                      </li>
                      <li>
                        <a href="/blister-cards" class="drop-text">
                          Blister Cards
                        </a>
                      </li>
                      <li>
                        <a href="/labels-and-stickers" class="drop-text">
                          Labels &amp; Stickers
                        </a>
                      </li>
                      <li>
                        <a href="/paper-carry-bags" class="drop-text">
                          Paper Carry Bags
                        </a>
                      </li>
                      <li>
                        <a href="/tags" class="drop-text">
                          Tags
                        </a>
                      </li>
                      <li>
                        <a href="/rigid-boxes" class="drop-text">
                          Rigid Boxes
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    {" "}
                    {/* <HashLink
                      to="/#about-pio-packaging"
                      scroll={(el) =>
                        el.scrollIntoView({
                          behavior: "smooth",
                        })
                      }
                    >
                      About Pio Packaging
                    </HashLink> */}
                    <a href="/#about-pio-packaging">About Pio Packaging</a>
                  </li>
                  <li>
                    <a href="/enquiry">Enquiry</a>
                  </li>
                  <li>
                    <a href="/blogs">Blogs</a>
                  </li>
                  <li>
                    {" "}
                    {/* <HashLink
                      to="/#ContactUs"
                      scroll={(el) =>
                        el.scrollIntoView({
                          behavior: "smooth",
                        })
                      }
                    >
                      Contact
                    </HashLink> */}
                    <a href="/#ContactUs">Contact</a>
                    
                  </li>
                  {/* {logindetails != null ? (
                    <li>
                      <a
                        onClick={() => {
                          localStorage.removeItem("CustomerData");
                          window.location.href = "/";
                        }}
                        style={{ color: "white" }}
                      >
                        Log Out
                      </a>
                    </li>
                  ) : (
                    <li>
                      <a href="/login">Login</a>
                    </li>
                  )} */}

                  <li>
                    <a
                      className="mx-lg-3 btn shopnowbtn"
                      // href="/ordercustomboxes"
                      href="/onlinestore"
                      style={{
                        background: "#59c5d1",
                        borderRadius: "3px",
                        color: "white",
                      }}
                    >
                      Online Store
                    </a>
                  </li>

                  {name == null ? (
                    <li>
                      <a
                        className="mx-lg-3 btn shopnowbtn"
                        href="/login"
                        style={{
                          background: "#59c5d1",
                          borderRadius: "3px",
                          color: "white",
                        }}
                      >
                        <i class="fa-solid fa-user"></i> Login
                      </a>
                    </li>
                  ) : (
                    <li>
                      <a
                        href="#"
                        onClick={() => {
                          this.setState({
                            mobileMenuShow: !this.state.mobileMenuShow,
                          });
                        }}
                        id="has-child2"
                        className="mx-lg-3 btn shopnowbtn"
                        style={{
                          background: "#59c5d1",
                          borderRadius: "3px",
                          color: "white",
                        }}
                      >
                        <i class="fa-solid fa-user"></i> {name}{" "}
                        <i class="fa fa-angle-down"></i>
                      </a>
                      <ul
                        class="dp-menu pio-dropdown"
                        id="dp-menu"
                        style={{
                          display:
                            this.state.mobileMenuShow == true
                              ? "block"
                              : "none",
                        }}
                      >
                        <li>
                          <a href="/customerprofile" class="drop-text">
                            My Account
                          </a>
                        </li>
                        <li>
                          <a href="/my-orders" class="drop-text">
                            My Orders
                          </a>
                        </li>
                        <li>
                          <a
                            class="drop-text"
                            style={{ color: "#fff", cursor: "pointer" }}
                            onClick={() => {
                              this.setState({
                                changePasswordVerify: true,
                              });
                            }}
                          >
                            Change Password
                          </a>
                        </li>

                        <li>
                          <a
                            style={{ color: "#fff", cursor: "pointer" }}
                            onClick={() => {
                              localStorage.removeItem("CustomerData");
                              window.location.href = "/";
                            }}
                            class="drop-text"
                          >
                            Logout
                          </a>
                        </li>
                      </ul>
                    </li>
                  )}

                  <div
                    class="menu-custom-box"
                    style={{
                      marginRight:
                        this.state.customBoxSelection == true ? "0%" : "-70%",
                    }}
                  >
                    <p class="menu-custom-box-title mb-3 border-bottom">
                      Your Customised Box
                    </p>
                    {this.context.selectedBox == "" &&
                      this.context.selectedDesign == "" &&
                      this.context.selectedOuterCoating == "" &&
                      this.context.selectedInnerCoating == "" &&
                      this.context.selectedQualityBoard == "" ? (
                      <>
                        <p className="text-white mb-2">
                          No custom selection available. Please customize your
                          box now.
                        </p>
                        <button
                          title=""
                          class="btn designbtn2 mx-0 active"
                          onClick={() => {
                            this.setState({ customBoxSelection: false });
                          }}
                        >
                          Ok
                        </button>
                      </>
                    ) : (
                      <div class="menu-custom-design-selection">
                        <div
                          class="row mb-3 pb-3 border-bottom"
                          style={{
                            display:
                              this.context.selectedBox == "" ? "none" : "block",
                          }}
                        >
                          <div class="col">
                            <p class="text-white text-left">Selected Box</p>
                            <img src={this.context.selectedBox} alt=""></img>
                          </div>
                        </div>
                        <div
                          class="row mb-3 pb-3 border-bottom"
                          style={{
                            display:
                              this.context.selectedDesign == ""
                                ? "none"
                                : "block",
                          }}
                        >
                          <div class="col">
                            <p class="text-white text-left">Selected Design</p>
                            <img src={this.context.selectedDesign} alt=""></img>
                          </div>
                        </div>
                        <div
                          class="row mb-3 pb-3 border-bottom"
                          style={{
                            display:
                              this.context.selectedOuterCoating == ""
                                ? "none"
                                : "block",
                          }}
                        >
                          <div class="col">
                            <p class="text-white text-left">
                              Selected Outer Coating
                            </p>
                            <img
                              src={this.context.selectedOuterCoating}
                              alt=""
                            ></img>
                          </div>
                        </div>
                        <div
                          class="row mb-3 pb-3 border-bottom"
                          style={{
                            display:
                              this.context.selectedInnerCoating == ""
                                ? "none"
                                : "block",
                          }}
                        >
                          <div class="col">
                            <p class="text-white text-left">
                              Selected Inner Coating
                            </p>
                            <img
                              src={this.context.selectedInnerCoating}
                              alt=""
                            ></img>
                          </div>
                        </div>
                        <div
                          class="row mb-3 pb-3 border-bottom"
                          style={{
                            display:
                              this.context.selectedQualityBoard == ""
                                ? "none"
                                : "block",
                          }}
                        >
                          <div class="col">
                            <p class="text-white text-left">
                              Selected Quality Of Board
                            </p>
                            <img
                              src={this.context.selectedQualityBoard}
                              alt=""
                            ></img>
                          </div>
                        </div>
                      </div>
                    )}
                    <div class="d-flex justify-content-center align-content-center">
                      <button
                        style={{
                          display:
                            this.context.selectedBox == "" &&
                              this.context.selectedDesign == "" &&
                              this.context.selectedOuterCoating == "" &&
                              this.context.selectedInnerCoating == "" &&
                              this.context.selectedQualityBoard == ""
                              ? "none"
                              : "block",
                        }}
                        title=""
                        class="btn designbtn2 mx-0 active"
                        onClick={() => {
                          this.setState({ customBoxSelection: false });
                        }}
                      >
                        Done
                      </button>
                    </div>
                  </div>

                  <div class="menu-media show-mobile">
                    <p>Call Now</p>
                    <a href="tel:919677027016" class="menu-enquiry">
                      <i class="fa fa-mobile"></i> +91 96770 27016{" "}
                    </a>
                    <div class="menu-media">
                      <ul>
                        <li>
                          <a
                            href="https://www.facebook.com/PioPackaging"
                            class="facebook"
                            target="_blank"
                          >
                            <i class="fa fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/pio.packaging/"
                            class="instagram"
                            target="_blank"
                          >
                            <i class="fa fa-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/company/pio-packaging"
                            class="linkedin"
                            target="_blank"
                          >
                            <i class="fa fa-linkedin"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </ul>
                <Modal
                  backdrop={false}
                  size="lg"
                  isOpen={
                    this.state.changePasswordVerify == true ? true : false
                  }
                >
                  <ModalHeader
                    close={
                      <button
                        className="close"
                        onClick={() => {
                          this.setState({
                            changePasswordVerify: false,
                          });
                        }}
                      >
                        ×
                      </button>
                    }
                  >
                    Change Password
                  </ModalHeader>
                  <ModalBody>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        value={this.state.Email}
                        disabled
                      />
                    </div>
                    <p className="mb-2">To continue, first verify it’s you</p>
                    <div class="input-group mb-3">
                      <input
                        type="password"
                        class="form-control"
                        placeholder="Enter your password"
                        value={this.state.OldPassword}
                        onChange={(text) => {
                          this.setState({
                            OldPassword: text.target.value,
                          });
                        }}
                      />
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      className="btn shopnowbtn"
                      style={{
                        background: "#59c5d1",
                        border: "1px solid #59c5d1",
                        borderRadius: "3px",
                        color: "white",
                      }}
                      onClick={this.saveLogin}
                    >
                      Verify
                    </Button>{" "}
                  </ModalFooter>
                </Modal>
                <Modal
                  backdrop={false}
                  size="lg"
                  isOpen={this.state.changePasswordCard == true ? true : false}
                >
                  <ModalHeader
                    close={
                      <button
                        className="close"
                        onClick={() => {
                          this.setState({
                            changePasswordCard: false,
                          });
                        }}
                      >
                        ×
                      </button>
                    }
                  >
                    Change Password
                  </ModalHeader>
                  <ModalBody>
                    <div class="input-group mb-3">
                      <input
                        type="password"
                        class="form-control"
                        placeholder="New Password"
                        value={this.state.NewPassword}
                        onChange={(newPassword) => {
                          this.setState({
                            NewPassword: newPassword.target.value,
                          });
                        }}
                      />
                    </div>
                    <div class="input-group mb-3">
                      <input
                        type="password"
                        class="form-control"
                        placeholder="Confirm New Password"
                        value={this.state.ConfirmPassword}
                        onChange={(confirmPassword) => {
                          this.setState({
                            ConfirmPassword: confirmPassword.target.value,
                          });
                        }}
                      />
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      className="btn shopnowbtn"
                      style={{
                        background: "#59c5d1",
                        border: "1px solid #59c5d1",
                        borderRadius: "3px",
                        color: "white",
                      }}
                      onClick={this.SavePassword}
                    >
                      Change Password
                    </Button>{" "}
                  </ModalFooter>
                </Modal>
              </nav>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
